import React, {Component} from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import Header from "./Header";
import About from "./components/about";
import {path} from "./path";
import TokenManager from "./components/TokenManager";
import {globalState} from "../../services/globalstate";
import CreateEditToken from "./components/manager-component/createEditToken";
import AppTable from "./components/appTable";
import AppRegistration from "./components/app-components/appRegisteration";
import SubUser from "./components/SubUser";
import CreateSubUser from "./components/sub-user-component/createSubUser";
import Acl from './components/ACL-component/ACL'
import Acla from './components/ACL-component/ShowAcl'

class Panel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: globalState.getLocalStorage('username')?globalState.getLocalStorage('username'):'user',
            path:path,
        };
        this.redirect = this.redirect.bind(this);
    }

    componentWillMount() {
        if ( globalState.getLocalStorage('sub_user_authenticated') ) {
            let temp = [];
            JSON.parse(JSON.stringify(path)).forEach((e, i) => {
                temp[i] = [];
                e.forEach((key) => {
                    if ( !key.onlyAdmin ) {
                        temp[i].push(key);
                    }
                })
            });
            this.setState({path:temp});
        }
    }

    render() {
        return (
            <div>
                <Header
                    username={this.state.username}
                    storeName=""
                    {...this.props}
                    navBarItems={this.state.path}
                    redirect={this.redirect}>
                    <Switch>
                        <Route exact path="/panel/token" component={TokenManager}/>
                        <Route exact path="/panel/token/edit/:id" component={CreateEditToken}/>
                        <Route exact path="/panel/token/create" component={CreateEditToken}/>
                        <Route exact path="/panel/app" component={AppTable}/>
                        <Route exact path="/panel/app/registration" component={AppRegistration}/>
                        <Route exact path="/panel/app/edit/:id" component={AppRegistration}/>
                        <Route exact path="/panel/subuser" component={SubUser}/>
                        <Route exact path="/panel/acl" component={Acl}/>
                        <Route exact path="/panel/acl/viewGroup" component={Acla}/>
                        <Route exact path="/panel/acl/AddGroup" component={Acla}/>
                        <Route exact path="/panel/subuser/registration" component={CreateSubUser}/>
                        <Route exact path="/panel/subuser/edit/:id" component={CreateSubUser}/>
                        <Route exact path="/panel/about" component={About}/>
                        <Redirect path="**" to='/panel/app'/>
                    </Switch>
                </Header>
            </div>
        );
    }
    redirect(url) {
        if ( url === '/auth' ) {
            globalState.removeAllLocalStorage();
        }
        this.props.history.push(url);
    }
}

export default withRouter(Panel);