import React, { Component } from "react";
import {
  Card,
  TextField,
  Page,
  FormLayout,
  Stack,
  Button,
  DisplayText,
  ContextualSaveBar,
  Toast,
  Select,
  Label,
} from "@shopify/polaris";
import { requests } from "../../../../services/request";

const defaultKeys = {
  key: "",
  value: "",
  error: false,
  errorText: "",
  disable: {
    key: false,
    value: false,
  },
};

const marketPlacesArray = {
  shopify: {
    keys: [
      "app_key",
      "app_secret",
      "scope",
      "redirect_uri",
      "rest_ver",
      "graphQL_ver",
      "app_id",
      "group_code",
      "sales_channel",
    ],
    canHaveMore: false,
  },
  facebook: {
    keys: ["app_id", "app_secret", "redirect_uri", "scope", "system_token"],
    canHaveMore: false,
  },
  lazada: {
    keys: ["app_key", "app_secret"],
    canHaveMore: false,
  },
  ebay: {
    keys: ["app_key", "app_secret", "dev_id", "runame", "scope"],
    canHaveMore: false,
  },
  etsy: {
    keys: ["consumer_key", "secret_key", "scope"],
    canHaveMore: false,
  },
  amazon: {
    keys: [
      "region",
      "dev_id",
      "access_key",
      "secret_key",
      "redirect_uri",
      "sp_oauth_url",
      "sp_application_id_sandbox",
      "sp_application_id_production",
    ],
    canHaveMore: false,
  },
  hubspot: {
    keys: [
      "hapi_key",
      "app_id",
      "api_version",
      "client_api",
      "secret_id",
      "redirect_uri",
      "base_url",
    ],
    canHaveMore: false,
  },
  zalando: {
    keys: ["client_secret", "client_id"],
    canHaveMore: false,
  },
  mercado_cbt: {
    keys: ["app_id", "app_secret", "redirect_uri"],
    canHaveMore: false,
  },
  vidaxl: {
    keys: ["default"],
    canHaveMore: false,
  },
  bigcommerce: {
    keys: [
      "app_key",
      "app_secret",
      "scope",
      "redirect_uri",
      "app_id",
      "group_code",
      "sales_channel",
      "base_url",
    ],
    canHaveMore: false,
  },
};

const statusArray = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
  { label: "Under Development", value: "Under Development" },
];

class AppRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appGeneralDetails: [
        { key: "name", label: "App Name" },
        { key: "url", label: "App Location (URL)" },
        {
          key: "status",
          label: "App Status",
          value: "Under Development",
          type: "select",
          arrayOption: statusArray,
          optional: true,
        },
        {
          key: "additional_input",
          label: "Additional Data (Optional)",
          optional: true,
        },
        { key: "group_code", label: "Group Code", optional: true },
        { key: "app_code", label: "App Code", optional: true },
        { key: "marketPlaceHandler", label: "MarketPlace Handler" },
      ].map((c) => ({ ...defaultKeys, ...c })),
      enteredAppCredentials: [JSON.parse(JSON.stringify(defaultKeys))],
      enteredSandboxCredentials: [JSON.parse(JSON.stringify(defaultKeys))],
      appID: props.match.params.id ? props.match.params.id : null,
      ContextualSaveBarShow: false,
      marketPlaceValue: "shopify",
      canHaveMore: true,
      enteredAppCredentialsAmazon: {
        EU: [],
        NA: [],
        FE: [],
        IN: [],
      },
    };
    this.onAdd = this.onAdd.bind(this);
    this.onSub = this.onSub.bind(this);
    this.toggleToast = this.toggleToast.bind(this);
    this.handleMarketPlaceChange = this.handleMarketPlaceChange.bind(this);
  }

  getByID() {
    if (this.state.appID !== null) {
      requests
        .getRequest("webapi/rest/v1/apps?id=" + this.state.appID)
        .then((e) => {
          if (e.success && e.data !== null) {
            this.convertDataToDefaultFormat(e.data);
          } else {
            if (e.message) this.toggleToast(e.message, true);
            else this.toggleToast("No App Found With given Key", true);
          }
          this.saveOldDetails();
        });
    } else {
      this.saveOldDetails();
    }
  }

  componentDidMount() {
    if (this.state.appID !== null) {
      this.getByID();
    } else {
      this.saveOldDetails();
    }
    this.handleMarketPlaceChange(this.state.marketPlaceValue);
  }

  convertDataToDefaultFormat = (value) => {
    let { appGeneralDetails, enteredAppCredentialsAmazon } = this.state;
    let enteredAppCredentials = [];
    let enteredSandboxCredentials = [];
    delete value["write_connection_service"];
    delete value["_id"];

    let marketplace = value["marketplace"] ? value["marketplace"] : "";

    if (value["marketplace"] !== undefined) {
      enteredAppCredentials = this.handleMarketPlaceChange(
        value["marketplace"],
        value
      );
      if (marketplace !== "amazon")
        enteredSandboxCredentials = this.handleMarketPlaceChange(
          value["marketplace"]
        );
      delete value["marketplace"];
    }
    appGeneralDetails.forEach((e) => {
      if (value[e.key] !== undefined) {
        e.value = value[e.key];
        delete value[e.key];
      }
    });

    if (marketplace !== "amazon") {
      if (value["live"] !== undefined && value["sandbox"] !== undefined) {
        Object.keys(value["live"]).map((e) => {
          enteredAppCredentials.forEach((appCr, index) => {
            if (appCr.key === e) {
              // if ( enteredAppCredentials[index] === undefined ) enteredAppCredentials.push(JSON.parse(JSON.stringify(defaultKeys)));
              enteredAppCredentials[index]["value"] = value["live"][e];
              enteredAppCredentials = JSON.parse(
                JSON.stringify(enteredAppCredentials)
              );
            }
          });
        });
        enteredSandboxCredentials = JSON.parse(
          JSON.stringify(enteredSandboxCredentials)
        );
        Object.keys(value["sandbox"]).map((e) => {
          enteredSandboxCredentials.forEach((appCr, index) => {
            if (appCr.key === e) {
              enteredSandboxCredentials[index]["value"] = value["sandbox"][e];
              enteredSandboxCredentials = JSON.parse(
                JSON.stringify(enteredSandboxCredentials)
              );
            }
          });
        });
      } else {
        Object.keys(value).map((e) => {
          enteredAppCredentials.forEach((appCr, index) => {
            if (appCr.key === e) {
              // if ( enteredAppCredentials[index] === undefined ) enteredAppCredentials.push(JSON.parse(JSON.stringify(defaultKeys)));
              enteredAppCredentials[index]["value"] = value[e];
              enteredAppCredentials = JSON.parse(
                JSON.stringify(enteredAppCredentials)
              );
              enteredSandboxCredentials[index]["value"] = value[e];
              enteredSandboxCredentials = JSON.parse(
                JSON.stringify(enteredSandboxCredentials)
              );
            }
          });
        });
      }
    }
    this.setState({
      appGeneralDetails: appGeneralDetails,
      enteredAppCredentials: enteredAppCredentials,
      enteredSandboxCredentials: enteredSandboxCredentials,
    });
  };

  fillAmazonDetails = (enteredAppCredentialsAmazon, value) => {
    if (Object.keys(value).length === 0) return true;

    Object.keys(enteredAppCredentialsAmazon).forEach((key) => {
      if (value[key] !== undefined) {
        Object.keys(value[key]).map((e) => {
          enteredAppCredentialsAmazon[key].forEach((appCr, index) => {
            if (appCr.key === e) {
              // if ( enteredAppCredentialsAmazon[key][index] === undefined ) enteredAppCredentialsAmazon.push(JSON.parse(JSON.stringify(defaultKeys)));
              enteredAppCredentialsAmazon[key][index]["value"] = value[key][e];
            }
          });
        });
      }
    });
    console.log(enteredAppCredentialsAmazon);
    this.setState({ enteredAppCredentialsAmazon: enteredAppCredentialsAmazon });
  };

  renderApp = () => {
    return (
      <React.Fragment>
        <div className="row mt-5 mb-5">
          <div className="col-12 col-sm-3 p-5">
            <DisplayText size="small">
              <b>App details</b>
            </DisplayText>
            <br />
            <p>Enter the your App credentials.</p>
          </div>
          <div className="col-12 col-sm-9">
            <Card sectioned>
              <FormLayout>
                {this.state.enteredAppCredentials.map((key, index) => {
                  return (
                    <Stack
                      wrap={false}
                      alignment="leading"
                      spacing="tight"
                      key={index}
                    >
                      <Stack.Item fill>
                        <Stack distribution="fill" spacing="tight">
                          {key.disable.key ? (
                            <Label id={"123"}>{key.key}</Label>
                          ) : (
                            <TextField
                              label="Key"
                              placeholder="Key"
                              labelHidden
                              disabled={key.disable.key}
                              error={
                                this.state.enteredAppCredentials[index][
                                  "errorText"
                                ]
                              }
                              value={
                                this.state.enteredAppCredentials[index]["key"]
                              }
                              onChange={(e) => {
                                this.handleOnChange(index, "key", e);
                              }}
                              readOnly={false}
                            />
                          )}
                          <TextField
                            type="Link"
                            label="value"
                            disabled={key.disable.value}
                            placeholder="Value"
                            labelHidden
                            value={
                              this.state.enteredAppCredentials[index]["value"]
                            }
                            onChange={(e) => {
                              this.handleOnChange(index, "value", e);
                            }}
                            readOnly={false}
                          />
                        </Stack>
                      </Stack.Item>
                      {index !== 0 ? (
                        <Button
                          destructive
                          disabled={this.state.canHaveMore}
                          icon="subtract"
                          onClick={this.onSub.bind(this, index)}
                        />
                      ) : (
                        <Button
                          disabled={this.state.canHaveMore}
                          primary
                          icon="add"
                          onClick={this.onAdd}
                        />
                      )}
                    </Stack>
                  );
                })}
              </FormLayout>
            </Card>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-12 col-sm-3 p-5">
            <DisplayText size="small">
              <b>Sandbox details</b>
            </DisplayText>
            <br />
            <p>Enter the your App Sandbox credentials.</p>
          </div>
          <div className="col-12 col-sm-9">
            <Card sectioned>
              <FormLayout>
                {this.state.enteredSandboxCredentials.map((key, index) => {
                  return (
                    <Stack
                      wrap={false}
                      alignment="leading"
                      spacing="tight"
                      key={index}
                    >
                      <Stack.Item fill>
                        <Stack distribution="fill" spacing="tight">
                          {key.disable.key ? (
                            <Label id={"123"}>{key.key}</Label>
                          ) : (
                            <TextField
                              label="Key"
                              placeholder="Key"
                              labelHidden
                              disabled={key.disable.key}
                              error={
                                this.state.enteredSandboxCredentials[index][
                                  "errorText"
                                ]
                              }
                              value={
                                this.state.enteredSandboxCredentials[index][
                                  "key"
                                ]
                              }
                              onChange={(e) => {
                                this.handleSandboxOnChange(index, "key", e);
                              }}
                              readOnly={false}
                            />
                          )}
                          <TextField
                            type="Link"
                            label="value"
                            disabled={key.disable.value}
                            placeholder="Value"
                            labelHidden
                            value={
                              this.state.enteredSandboxCredentials[index][
                                "value"
                              ]
                            }
                            onChange={(e) => {
                              this.handleSandboxOnChange(index, "value", e);
                            }}
                            readOnly={false}
                          />
                        </Stack>
                      </Stack.Item>
                      {index !== 0 ? (
                        <Button
                          destructive
                          disabled={this.state.canHaveMore}
                          icon="subtract"
                          onClick={this.onSub.bind(this, index)}
                        />
                      ) : (
                        <Button
                          disabled={this.state.canHaveMore}
                          primary
                          icon="add"
                          onClick={this.onAdd}
                        />
                      )}
                    </Stack>
                  );
                })}
              </FormLayout>
            </Card>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderAmazonApp = () => {
    let { enteredAppCredentialsAmazon } = this.state;

    return Object.keys(enteredAppCredentialsAmazon).map((AppCredKey) => {
      return (
        <React.Fragment key={AppCredKey}>
          <div className="row mt-5 mb-5">
            <div className="col-12 col-sm-3 p-5">
              <DisplayText size="small">
                <b>Amazon {AppCredKey} details</b>
              </DisplayText>
              <br />
              <p>Enter the your App credentials.</p>
            </div>
            <div className="col-12 col-sm-9">
              <Card sectioned>
                <FormLayout>
                  {enteredAppCredentialsAmazon[AppCredKey].map((key, index) => {
                    return (
                      <Stack
                        wrap={false}
                        alignment="leading"
                        spacing="tight"
                        key={index}
                      >
                        <Stack.Item fill>
                          <Stack distribution="fill" spacing="tight">
                            {key.disable.key ? (
                              <Label id={"123"}>{key.key}</Label>
                            ) : (
                              <TextField
                                label="Key"
                                placeholder="Key"
                                labelHidden
                                disabled={key.disable.key}
                                error={
                                  enteredAppCredentialsAmazon[AppCredKey][
                                    index
                                  ]["errorText"]
                                }
                                value={
                                  enteredAppCredentialsAmazon[AppCredKey][
                                    index
                                  ]["key"]
                                }
                                onChange={(e) => {
                                  this.handleAmazonOnChange(
                                    index,
                                    "key",
                                    e,
                                    AppCredKey
                                  );
                                }}
                                readOnly={false}
                              />
                            )}
                            <TextField
                              type="Link"
                              label="value"
                              disabled={key.disable.value}
                              placeholder="Value"
                              labelHidden
                              value={
                                enteredAppCredentialsAmazon[AppCredKey][index][
                                  "value"
                                ]
                              }
                              onChange={(e) => {
                                this.handleAmazonOnChange(
                                  index,
                                  "value",
                                  e,
                                  AppCredKey
                                );
                              }}
                              readOnly={false}
                            />
                          </Stack>
                        </Stack.Item>
                        {index !== 0 ? (
                          <Button
                            destructive
                            disabled={this.state.canHaveMore}
                            icon="subtract"
                            onClick={this.onSub.bind(this, index)}
                          />
                        ) : (
                          <Button
                            disabled={this.state.canHaveMore}
                            primary
                            icon="add"
                            onClick={this.onAdd}
                          />
                        )}
                      </Stack>
                    );
                  })}
                </FormLayout>
              </Card>
            </div>
          </div>
        </React.Fragment>
      );
    });
  };

  renderGeneralApp = () => {
    return (
      <div className="row mt-5 mb-5">
        <div className="col-12 col-sm-3 p-5">
          <DisplayText size="small">
            <b>General details</b>
          </DisplayText>
          <br />
          <p>Enter the default details for your App..</p>
        </div>
        <div className="col-12 col-sm-9">
          <Card sectioned>
            <FormLayout>
              {this.state.appGeneralDetails.map((key, index) => {
                if (key.type === "select") {
                  return (
                    <Select
                      key={index}
                      label={key.label}
                      labelInline
                      options={key.arrayOption}
                      value={key.value}
                      onChange={(e) => {
                        this.handleGeneralOnChange(index, "value", e);
                      }}
                      readOnly={false}
                    />
                  );
                }
                console.log(key);
                return (
                  <FormLayout.Group key={index}>
                    <TextField
                      type="Link"
                      error={key["errorText"]}
                      label={key["label"]}
                      labelHidden
                      placeholder={key["label"]}
                      value={key["value"]}
                      onChange={(e) => {
                        this.handleGeneralOnChange(index, "value", e);
                      }}
                      readOnly={false}
                    />
                  </FormLayout.Group>
                );
              })}
            </FormLayout>
          </Card>
        </div>
      </div>
    );
  };

  renderMarketPlaceDropDown = () => {
    let key = [];
    Object.keys(marketPlacesArray).forEach((e) => {
      key.push({ label: e, value: e });
    });
    return (
      <div className="row">
        <div className="offset-8 offset-sm-8 col-4 col-sm-3">
          <Select
            label={"MarketPlace"}
            labelInline
            placeholder="Select"
            options={key}
            value={this.state.marketPlaceValue}
            onChange={(e) => {
              this.handleMarketPlaceChange(e);
            }}
            disabled={this.state.appID !== null}
          />
        </div>
      </div>
    );
  };

  render() {
    const { ContextualSaveBarShow, marketPlaceValue } = this.state;

    let showContext = ContextualSaveBarShow ? (
      <ContextualSaveBar
        message="Unsaved changes"
        saveAction={{
          onAction: this.handleSave,
        }}
        discardAction={{
          onAction: this.handleDiscard,
        }}
      />
    ) : null;
    const { showToast } = this.state;
    const toastMarkup = showToast ? (
      <Toast
        content={this.state.message}
        error={this.state.showToastError}
        onDismiss={this.toggleToast}
      />
    ) : null;
    return (
      <Page
        title={this.state.appID ? "Edit App" : "Create App"}
        fullWidth
        primaryAction={{
          content: "Back",
          onClick: () => {
            this.redirect("/panel/app");
          },
        }}
      >
        {toastMarkup}
        {showContext}
        {this.renderMarketPlaceDropDown()}
        <hr />
        {this.renderGeneralApp()}
        <hr />
        {marketPlaceValue === "amazon"
          ? this.renderAmazonApp()
          : this.renderApp()}
        <hr />
      </Page>
    );
  }

  handleMarketPlaceChange(e, liveVal = {}) {
    let enteredAppCredentials = [JSON.parse(JSON.stringify(defaultKeys))];
    let canHaveMore = true;
    let marketPlaceValue = e;
    Object.keys(marketPlacesArray).forEach((keys) => {
      if (keys === e && marketPlacesArray[keys]["keys"]) {
        canHaveMore = !marketPlacesArray[keys]["canHaveMore"];
        marketPlacesArray[keys]["keys"].map((e, index) => {
          if (enteredAppCredentials[index] === undefined)
            enteredAppCredentials.push(JSON.parse(JSON.stringify(defaultKeys)));
          enteredAppCredentials[index]["key"] = e;
          enteredAppCredentials[index]["value"] = "";
          enteredAppCredentials[index]["disable"]["key"] = true;
          enteredAppCredentials = JSON.parse(
            JSON.stringify(enteredAppCredentials)
          );
        });
      }
    });

    if (marketPlaceValue === "amazon") {
      let enteredAppCredentialsAmazon = {};
      Object.keys(this.state.enteredAppCredentialsAmazon).map((key) => {
        enteredAppCredentialsAmazon[key] = JSON.parse(
          JSON.stringify(enteredAppCredentials)
        );
      });
      this.setState(
        { enteredAppCredentialsAmazon: enteredAppCredentialsAmazon },
        () => {
          this.fillAmazonDetails(enteredAppCredentialsAmazon, liveVal);
        }
      );
    }

    this.setState({
      marketPlaceValue: marketPlaceValue,
      enteredAppCredentials: enteredAppCredentials,
      enteredSandboxCredentials: JSON.parse(
        JSON.stringify(enteredAppCredentials)
      ),
      canHaveMore: canHaveMore,
    });
    return JSON.parse(JSON.stringify(enteredAppCredentials));
  }

  saveOldDetails = () => {
    this.setState({ oldState: JSON.parse(JSON.stringify(this.state)) });
  };

  SaveAmazonData = () => {
    let sendData = {},
      flag = true;

    let { marketPlaceValue, enteredAppCredentialsAmazon, appGeneralDetails } =
      this.state;

    if (this.state.appID !== null) {
      sendData["id"] = parseInt(this.state.appID);
    }

    appGeneralDetails.forEach((e, index) => {
      if (e["value"] !== "" || e["optional"]) {
        sendData[e["key"]] = e["value"];
      } else {
        flag = false;
        this.toggleToast("Cannot Be Empty.", true);
        appGeneralDetails[index]["error"] = true;
        appGeneralDetails[index]["errorText"] = "Cannot Be Empty.";
      }
    });

    Object.keys(enteredAppCredentialsAmazon).forEach((keys) => {
      enteredAppCredentialsAmazon[keys].forEach((e, index) => {
        if (!sendData[keys]) sendData[keys] = {};

        if (
          sendData[keys][e["key"]] === undefined &&
          e["key"] !== "" &&
          e["value"] !== ""
        ) {
          sendData[keys][e["key"]] = e["value"];
        } else {
          enteredAppCredentialsAmazon[keys][index]["error"] = true;
          if (e["key"] === "" || e["value"] === "") {
            enteredAppCredentialsAmazon[keys][index]["errorText"] =
              "Empty Key or Value Will Be ignored";
          } else {
            this.toggleToast(
              "Duplicate Key found , same as general setting.",
              true
            );
            flag = false;
            enteredAppCredentialsAmazon[keys][index]["errorText"] =
              "Duplicate Key found , same as general setting.";
          }
        }
      });
    });

    sendData["marketplace"] = marketPlaceValue;

    if (flag) {
      requests.putRequest("webapi/rest/v1/apps", sendData).then((e) => {
        if (e.success) {
          this.toggleToast(e.message);
          this.setState({ ContextualSaveBarShow: false });
          this.getByID();
        } else {
          this.toggleToast(e.message, true);
        }
      });
    }
  };

  handleSave = () => {
    let {
      enteredAppCredentials,
      appGeneralDetails,
      marketPlaceValue,
      enteredSandboxCredentials,
    } = this.state;

    if (marketPlaceValue === "amazon") {
      this.SaveAmazonData();
      return true;
    }

    let sendData = {};
    let flag = true;
    appGeneralDetails.forEach((e, index) => {
      if (e["value"] !== "" || e["optional"]) {
        sendData[e["key"]] = e["value"];
      } else {
        flag = false;
        this.toggleToast("Cannot Be Empty.", true);
        appGeneralDetails[index]["error"] = true;
        appGeneralDetails[index]["errorText"] = "Cannot Be Empty.";
      }
    });
    sendData["live"] = {};
    enteredAppCredentials.forEach((e, index) => {
      if (
        sendData["live"][e["key"]] === undefined &&
        e["key"] !== "" &&
        e["value"] !== ""
      ) {
        sendData["live"][e["key"]] = e["value"];
      } else {
        enteredAppCredentials[index]["error"] = true;
        if (e["key"] === "" || e["value"] === "") {
          enteredAppCredentials[index]["errorText"] =
            "Empty Key or Value Will Be ignored";
        } else {
          this.toggleToast(
            "Duplicate Key found , same as general setting.",
            true
          );

          flag = false;
          enteredAppCredentials[index]["errorText"] =
            "Duplicate Key found , same as general setting.";
        }
      }
    });
    sendData["sandbox"] = {};
    enteredSandboxCredentials.forEach((e, index) => {
      if (
        sendData["sandbox"][e["key"]] === undefined &&
        e["key"] !== "" &&
        e["value"] !== ""
      ) {
        sendData["sandbox"][e["key"]] = e["value"];
      } else {
        enteredSandboxCredentials[index]["error"] = true;
        if (e["key"] === "" || e["value"] === "") {
          enteredSandboxCredentials[index]["errorText"] =
            "Empty Key or Value Will Be ignored";
        } else {
          flag = false;
          this.toggleToast(
            "Duplicate Key found , same as general setting.",
            true
          );
          enteredSandboxCredentials[index]["errorText"] =
            "Duplicate Key found , same as general setting.";
        }
      }
    });
    if (flag) {
      if (this.state.appID !== null) {
        sendData["id"] = parseInt(this.state.appID);
      }
      sendData["marketplace"] = marketPlaceValue;
      // console.log(sendData);
      requests.putRequest("webapi/rest/v1/apps", sendData).then((e) => {
        if (e.success) {
          this.toggleToast(e.message);
          this.setState({ ContextualSaveBarShow: false });
          this.getByID();
        } else {
          this.toggleToast(e.message, true);
        }
      });
    } else {
      // this.toggleToast('Error In Validation',true)
    }
    this.setState({
      enteredAppCredentials: enteredAppCredentials,
      enteredSandboxCredentials: enteredSandboxCredentials,
      appGeneralDetails: appGeneralDetails,
    });
  };

  handleOnChange = (index, key, value) => {
    let { enteredAppCredentials } = this.state;
    enteredAppCredentials[index][key] = value;
    enteredAppCredentials[index]["error"] = false;
    enteredAppCredentials[index]["errorText"] = "";
    this.setState({
      enteredAppCredentials: enteredAppCredentials,
      ContextualSaveBarShow: true,
    });
  };

  handleAmazonOnChange = (index, key, value, Marketplace) => {
    let { enteredAppCredentialsAmazon } = this.state;
    enteredAppCredentialsAmazon[Marketplace][index][key] = value;
    enteredAppCredentialsAmazon[Marketplace][index]["error"] = false;
    enteredAppCredentialsAmazon[Marketplace][index]["errorText"] = "";
    this.setState({
      enteredAppCredentialsAmazon: enteredAppCredentialsAmazon,
      ContextualSaveBarShow: true,
    });
  };
  handleSandboxOnChange = (index, key, value) => {
    let { enteredSandboxCredentials } = this.state;
    enteredSandboxCredentials[index][key] = value;
    enteredSandboxCredentials[index]["error"] = false;
    enteredSandboxCredentials[index]["errorText"] = "";
    this.setState({
      enteredSandboxCredentials: enteredSandboxCredentials,
      ContextualSaveBarShow: true,
    });
  };

  handleGeneralOnChange = (index, key, value) => {
    let { appGeneralDetails } = this.state;
    appGeneralDetails[index][key] = value;
    appGeneralDetails[index]["error"] = false;
    appGeneralDetails[index]["errorText"] = "";
    this.setState({
      appGeneralDetails: appGeneralDetails,
      ContextualSaveBarShow: true,
    });
  };

  onAdd() {
    let { enteredAppCredentials, enteredSandboxCredentials } = this.state;
    enteredAppCredentials.push(JSON.parse(JSON.stringify(defaultKeys)));
    enteredSandboxCredentials.push(JSON.parse(JSON.stringify(defaultKeys)));
    this.setState({
      enteredAppCredentials: enteredAppCredentials,
      enteredSandboxCredentials: enteredSandboxCredentials,
    });
  }

  onSub(index) {
    let { enteredAppCredentials, enteredSandboxCredentials } = this.state;
    enteredAppCredentials.splice(index, 1);
    enteredSandboxCredentials.splice(index, 1);
    this.setState({
      enteredAppCredentials: enteredAppCredentials,
      enteredSandboxCredentials: enteredSandboxCredentials,
    });
  }

  handleDiscard = () => {
    this.setState(JSON.parse(JSON.stringify(this.state.oldState)));
  };

  toggleToast(message, error = false) {
    this.setState({
      showToast: !this.state.showToast,
      showToastError: error,
      message: message,
    });
  }

  redirect(url) {
    this.props.history.push(url);
  }
}

export default AppRegistration;
