import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AppProvider, Frame } from '@shopify/polaris';
import Auth from "./components/auth/auth";
import Panel from "./components/panel/panel";
import {globalState} from "./services/globalstate";

class App extends Component {
  render() {
    return (
      <AppProvider>
          <Frame>
              <Switch>
                  <Route path="/auth" render={() => {
                      if ( !globalState.getLocalStorage('auth_token') ) {
                          return <Auth/>
                      } else {
                          return <Redirect to="/panel"  />;
                      }
                  }}/>
                  <Route path="/panel" render={() => {
                      if ( globalState.getLocalStorage('auth_token') ) {
                          return <Panel/>
                      } else {
                          return <Redirect to="/auth"  />;
                      }
                  }}/>
                  <Redirect path="**" to="/auth"  />
              </Switch>
          </Frame>
      </AppProvider>
    );
  }
}

export default App;
