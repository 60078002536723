import React from 'react';
import {Page, Card, DataTable, Label, Button, Toast,Spinner, SkeletonBodyText, ContextualSaveBar,ButtonGroup} from '@shopify/polaris';
import {requests} from "../../../services/request";

class SubUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rows:[],
            deleteThisItem:'',
            loading:true,
        };
        this.getAllSubUsers();
        this.toggleToast = this.toggleToast.bind(this);
    }

    getAllSubUsers = () => {
        requests.getRequest('sub-user/getSubUsers?count=100&activePage=1').then(e => {
            if ( e.success ) {
                if ( e.data.count > 0 ) {
                    this.setState({rows:this.modifyDataRows(e.data.rows)});
                } else {
                    this.toggleToast( "No Sub User Found.");
                }
            } else {
                this.toggleToast(e.message, true);
            }
            this.setState({loading: false});
        })
    };

    modifyDataRows(data) {
        let rows = [];
        data.forEach(e => {
            rows.push([
                <Label>{e.username}</Label>,
                <Label>{e.email}</Label>,
                <Label>{e.created_at}</Label>,
                <ButtonGroup>
                    <Button size="slim" primary onClick={() => {
                        this.redirect('/panel/subuser/edit/' + e._id);
                    }}>Edit</Button>
                    <Button icon="delete" destructive onClick={() => {
                        this.setState({deleteThisItem:e.username,ContextualSaveBarShow:true});
                    }}/>
                </ButtonGroup>]);
        });
        return rows;
    }

    handleSave = () => {
        let { deleteThisItem } = this.state;
        requests.getRequest('sub-user/delete?username=' + deleteThisItem).then(e => {
            if ( e.success ) {
                this.toggleToast(e.message)
            }else {
                this.toggleToast(e.message,true)
            }
            this.getAllSubUsers();
        });
        this.setState({ContextualSaveBarShow:false,deleteThisItem:''});
    };

    render() {
        const { ContextualSaveBarShow, loading } = this.state;
        let showContext = ContextualSaveBarShow ? <ContextualSaveBar
            message="Are you sure want to delete?"
            saveAction={{
                content:'Delete',
                destructive:true,
                onAction: this.handleSave,
            }}
            discardAction={{
                content:'Cancel',
                onAction: this.handleDiscard,
            }}/> : null;
        const {showToast} = this.state;
        const toastMarkup = showToast ? (
            <Toast content={this.state.message} error={this.state.showToastError} onDismiss={this.toggleToast} />
        ) : null;

        if ( loading ) {
            return <Page title={"Sub USer"}>
                <Card sectioned>
                    <Card>
                        <DataTable
                            columnContentTypes={['text','text','text','text']}
                            headings={[
                                <Label>username</Label>,
                                <Label>email</Label>,
                                <Label>created at</Label>,
                                <Label>action</Label>]}
                            rows={[
                                [<Spinner/>, <SkeletonBodyText/>, <SkeletonBodyText/>, <SkeletonBodyText/>],
                                [<Spinner/>, <SkeletonBodyText/>, <SkeletonBodyText/>, <SkeletonBodyText/>],
                            ]}/>
                    </Card>

                </Card>
            </Page>
        }

        return (
            <Page title={"Sub Users"} primaryAction={{content:"Create Sub User", onClick:() => {this.redirect('/panel/subuser/registration')}}}>
                {showContext}
                {toastMarkup}
                <Card>
                    <DataTable
                        columnContentTypes={['text','text','text','text']}
                        headings={[
                            <Label>username</Label>,
                            <Label>email</Label>,
                            <Label>created at</Label>,
                            <Label>action</Label>]}
                        rows={this.state.rows}/>
                </Card>
            </Page>
        );
    }

    handleDiscard = () => {
        let { deleteThisItem } = this.state;
        this.setState({ContextualSaveBarShow:false,deleteThisItem:''});
    };

    toggleToast(message, error = false) {
        this.setState({
            showToast:!this.state.showToast,
            showToastError: error,
            message: message,
        });
    }

    redirect(url) {
        this.props.history.push(url);
    }
}

export default SubUser;