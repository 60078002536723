export const path = [
    [
        {
            label: "Token Manager",
            icon: "home",
            onlyAdmin:true,
            routeToGo: '/panel/token',
        },
        {
            label: "App Manager",
            onlyAdmin:false,
            icon: "<svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M19 11h-7c-0.553 0-1 0.447-1 1v7c0 0.553 0.447 1 1 1h7c0.553 0 1-0.447 1-1v-7c0-0.553-0.447-1-1-1v0zM8 11v0c0.553 0 1 0.447 1 1v7c0 0.553-0.447 1-1 1h-7c-0.553 0-1-0.447-1-1v-7c0-0.553 0.447-1 1-1h7zM8 0v0c0.553 0 1 0.447 1 1v7c0 0.553-0.447 1-1 1h-7c-0.553 0-1-0.447-1-1v-7c0-0.553 0.447-1 1-1h7zM2 18h5v-5h-5v5zM2 7h5v-5h-5v5zM13 18v-5h5v5h-5zM12 6c-0.553 0-1-0.447-1-1s0.447-1 1-1h2v-2c0-0.553 0.447-1 1-1s1 0.447 1 1v2h2c0.553 0 1 0.447 1 1s-0.447 1-1 1h-2v2c0 0.553-0.447 1-1 1s-1-0.447-1-1v-2h-2z' /></svg>",
            routeToGo: '/panel/app',
        },
        {
            label: "Sub Users",
            icon: "<svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M19.895 4.553l-2-4C17.725.213 17.38 0 17 0H3c-.38 0-.725.214-.895.553l-2 4C.035 4.69 0 4.845 0 5v1c0 1.474.81 2.75 2 3.444V19c0 .553.448 1 1 1h4c.552 0 1-.447 1-1v-5h5c.553 0 1-.447 1-1s-.447-1-1-1H7c-.552 0-1 .447-1 1v5H4v-8c1.193 0 2.267-.525 3-1.357C7.733 9.475 8.807 10 10 10s2.267-.525 3-1.357c.733.832 1.807 1.357 3 1.357v1c0 .553.447 1 1 1s1-.447 1-1V9.444c1.19-.694 2-1.97 2-3.444V5c0-.155-.036-.31-.105-.447zM3.618 2h12.764l1 2H2.618l1-2zM4 8c-1.103 0-2-.897-2-2h4c0 1.103-.897 2-2 2zm6 0c-1.103 0-2-.897-2-2h4c0 1.103-.897 2-2 2zm6 0c-1.103 0-2-.897-2-2h4c0 1.103-.897 2-2 2zm2.293 6.293c.39-.39 1.023-.39 1.414 0s.39 1.023 0 1.414l-4 4c-.195.195-.45.293-.707.293s-.512-.098-.707-.293l-2-2c-.39-.39-.39-1.023 0-1.414s1.023-.39 1.414 0L15 17.586l3.293-3.293z'/></svg>",
            routeToGo: '/panel/subuser',
            onlyAdmin:true,
        },
        {
            label: "ACL",
            icon: "<svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M17 4c-1.126 0-2.098 0.631-2.611 1.551l-4.408-0.734c-0.099-2.671-2.287-4.817-4.981-4.817-2.757 0-5 2.243-5 5s2.243 5 5 5c0.384 0 0.754-0.053 1.113-0.135l1.382 3.041c-0.904 0.734-1.495 1.841-1.495 3.094 0 2.206 1.794 4 4 4s4-1.794 4-4c0-0.918-0.323-1.753-0.844-2.429l2.906-3.736c0.297 0.099 0.608 0.165 0.938 0.165 1.654 0 3-1.346 3-3s-1.346-3-3-3zM2 5c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.654-1.346 3-3 3s-3-1.346-3-3zM7.931 9.031c0.772-0.563 1.374-1.336 1.724-2.241l4.398 0.733c0.070 0.396 0.216 0.764 0.426 1.090l-2.892 3.718c-0.488-0.211-1.023-0.331-1.587-0.331-0.236 0-0.464 0.030-0.688 0.070l-1.381-3.039zM10 18c-1.103 0-2-0.897-2-2s0.897-2 2-2c1.103 0 2 0.897 2 2s-0.897 2-2 2zM17 8c-0.552 0-1-0.448-1-1s0.448-1 1-1c0.552 0 1 0.448 1 1s-0.448 1-1 1z'/></svg>",
            routeToGo: '/panel/acl',
            onlyAdmin: true,
        },
    ],
    [
        {
            label: 'Back to Auth',
            icon: 'logOut',
            routeToGo: '/auth',
            // url: '/auth'
        },
    ],
];