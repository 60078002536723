export const environment = {
	production: true,
	isLive: false,
	API_ENDPOINT: 'https://formpay-remote.martechapps.com/',
	//API_ENDPOINT: 'http://remote.local.cedcommerce.com:8080/',
	AppName: "API Connector",
	confirmation_link: 'http://192.168.4.93:3100/connector/app/auth/verify/',
	Bearer: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjBlNTlkOTA1NGNmYzk2ODFhNzc5MmY5Iiwicm9sZSI6ImFkbWluIiwiZXhwIjoxNjgyNjA1NDEyLCJpc3MiOiJodHRwczpcL1wvYXBwcy5jZWRjb21tZXJjZS5jb20iLCJhdWQiOiIxMjcuMC4wLjEiLCJ0b2tlbl9pZCI6IjYyNjk1MWU0MTY1Y2ViMWE0Yjc2N2YxMiJ9.NVPtZyffklT4jAc6V01NT-GvgDMQXIcpGIgNjtqP_xXKmB9gmjjChoR73JbOphSumdZ08zTPSjtOm_q29Zf5m3wxF5i0CzHR-sP7glWFRzuX-0jq0abD2ofS-cuki6w6OpE1NxzEnwF5JpIi9dBq34sIdgbtk5FhMSIDcna4WqRFHBS4nJ48yBnxbD9hk6m294lzL2XoNyqQX-auohKxYQfQUAIUcaETkeDzgH1dI7OFa8-3XzWcoZyLGk7HgFI_EqA_PzCeJUQ9mhzEKVL0qDJtxVR4MfJliJE5ZMtwXlU6d04nfD_JXXmGFI2Vx1Wtso4WMv4-u_LWNVDFKb4iug'
	//Bearer:
		// "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjBlNTlkOTA1NGNmYzk2ODFhNzc5MmY5Iiwicm9sZSI6ImFkbWluIiwiZXhwIjoxNjc4MzcxNTY1LCJpc3MiOiJodHRwczpcL1wvYXBwcy5jZWRjb21tZXJjZS5jb20iLCJhdWQiOiIxMjcuMC4wLjEiLCJ0b2tlbl9pZCI6IjYyMjhiNzZkYzdmNDA2NDM1YTYyMDU1MiJ9.TW-V24twU6HZWLcatzJWlT6wmFEOKLGo9Ndt97fOmYS-Cfa4fCAfDtgWTC3ZTOLVSQVLPN7-7UFjrfiBh8kjriou12f1GGOJDkGhkww3Jywx3q06ek2M_LUy2JGEu0CbL9V0pmJE0sGG0pYK2QQo6-PqX7K7Hbda-CZTEpaTkOZykK2L6Bs_BPKrNNeexZBeRG6mmav5Xk-oHRRsv0ncLbzh-oFJBqETfilbbUF5WgPaDA0u-yKcAfdUSu-i9ruWIuJAtzDh89KuOc3QJxh33P-AsCbGV7jYVqZ2POxCVsbv3WFh0w8M2E_GdcwSIia8x-W9KaRh-n0MxGReBs6YLg"
};
/* Bearer on the last line is App token for server */
