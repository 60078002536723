import React from 'react';
import { globalState } from "../../../services/globalstate";
import {
    Badge,
    Card,
    DataTable,
    Page,
    Stack,
    Button,
    TextField,
    TextStyle, Heading, DisplayText,
    ButtonGroup,
    Toast, Spinner, SkeletonBodyText,
    Label,
    ContextualSaveBar, Modal, Layout
} from "@shopify/polaris";

import {
    ClipboardMinor, AddMajor as AddMajorMonotone, EditMajor
} from '@shopify/polaris-icons';

import { requests } from "../../../services/request";

class AppTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            serverData: [],
            auth_url: [],
            ContextualSaveBarShow: false,
            loading: true,
            buttonLoading: true,
        };
        this.getAllTheApps();
        this.toggleToast = this.toggleToast.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }

    toggle(index) {
        let on = this.state.on;
        on[index] = !on[index];
        this.setState({
            on: on
        })
    }

    deleteTheAPP = (appID) => {
        requests.deleteRequest('webapi/rest/v1/apps?id=' + appID).then(e => {
            if (e.success) {
                this.toggleToast(e.message);
            } else {
                this.toggleToast(e.message, true);
            }
            this.getAllTheApps();
        })
    };

    checkStatus(key) {
        switch (key) {
            case 'Active': return 'success';
            case 'Inactive': return 'warning';
            default: return '';
        }
    }

    getAllTheApps = () => {
        requests.getRequest('webapi/rest/v1/apps').then(e => {
            if (e.success && typeof e.data === 'object') {
                this.setState((oldState) => {
                    oldState.serverData = e.data;
                    oldState.ContextualSaveBarShow = false;
                    this.fixTheState(oldState);
                    return oldState;
                }, this.getTokens);
            } else {
                this.toggleToast(e.message, true);
            }
            this.setState({ loading: false });
        })
    };

    getTokens() {
        this.setState({ buttonLoading: true });
        requests.getRequest('core/token/get').then(e => {
            if (e.success) {
                let { serverData } = this.state;
                Object.values(serverData).forEach((value, index) => {
                    if (!value['subUsers'] || !value['subUsers']['apps']) return true;
                    let app_id = value['subUsers']['apps']['app_id'];
                    for (let i = e.data.rows.length - 1; i >= 0; i--) {
                        if (parseInt(app_id) === parseInt(e.data.rows[i]['app_id'])) {
                            serverData[index]['tokenData'] = e.data.rows[i];
                        }
                    }
                });
                this.setState({ serverData: serverData });
            } else {
                this.toggleToast(e.message, true);
            }
            this.setState({ buttonLoading: false });
        });
    }

    createToken(data) {
        let sendData = {
            app_id: data['app_id'],
            duration: "3600",
            title: data['app_name'] + " REFRESH TOKEN",
            type: "refresh",
        };
        if (!globalState.getLocalStorage('sub_user_authenticated')) {
            sendData['domain'] = data.domain;
        }

        requests.postRequest('core/token/create', sendData).then(e => {
            if (e.success) {
                this.toggleToast(e.message);
                this.getTokens();
            } else {
                this.toggleToast(e.message, true);
            }
        })
    }

    toggleToast(message, error = false) {
        this.setState({
            showToast: !this.state.showToast,
            showToastError: error,
            message: message,
        });
    }

    handleAuthURLChange = (value, data) => {
        let { auth_url } = this.state;
        let flag = true;
        auth_url.forEach((e, i) => {
            if (e.app_id === data['app_id']) {
                flag = false;
                auth_url[i]['auth_url'] = value;
            }
        });
        if (flag) {
            data['auth_url'] = value;
            auth_url.push(data);
        }
        this.setState({ auth_url: auth_url, ContextualSaveBarShow: true });
    };

    getValue = (app) => {
        let { auth_url } = this.state;
        let url = '';
        let flag = true;
        auth_url.forEach(e => {
            if (e.app_id === app['app_id']) {
                flag = false;
                url = e.auth_url;
            }
        });
        if (flag) {
            url = app['auth_url'];
            auth_url.push(app);
            this.setState({ auth_url: auth_url });
        }
        return url;
    };

    // renderDataTable = (e) => {
    //     let rows = [];
    //     let auth_url = {};
    //     let token = [];
    //     e.forEach((key,index) => {
    //         if ( key['subUsers'] && key['subUsers']['apps'] ) {
    //             auth_url[index] = key['subUsers']['apps'];
    //         } else auth_url = {};
    //         try {
    //             token[index] = key['subUsers']['apps'].public_key.replace('\n','<br/>');
    //         }catch (e) {
    //             token[index] = '';
    //         }
    //         let temp = [
    //             <div className="mt-3">
    //                 <Button onClick={this.toggle.bind(this, index)} ariaControls="basic-collapsible">
    //                     <Label><TextStyle variation="strong">{key.name}</TextStyle></Label>
    //                 </Button>
    //             </div>,
    //             <div className="my-3">
    //             <Collapsible
    //             open = {this.state.on[index]}
    //             id="basic-collapsible"
    //             transition={{duration: '150ms', timingFunction: 'ease'}}
    //             className="mb-5"
    //             >
    //
    //             <Card sectioned>
    //             <Badge status={this.checkStatus(key['status'])} children={key.status? key.status : 'Not Available'}/>,
    //                 <Label>{key['additional_input']}</Label>,
    //                     {
    //                     globalState.getLocalStorage('sub_user_authenticated') && Object.keys(auth_url).length > 0 ?
    //                         <TextField
    //                             label={"Auth Url"}
    //                             labelHidden
    //                             value={this.getValue(auth_url[index])}
    //                             onChange={(ch) => {
    //                                 this.handleAuthURLChange(ch, auth_url[index]);
    //                             }} connectedRight={<Button
    //                             icon="circlePlusOutline" onClick={() => {
    //                             if ( token[index] && token[index] !== '' ) {
    //                                 var textField = document.createElement('textarea');
    //                                 textField.innerText = token[index];
    //                                 document.body.appendChild(textField);
    //                                 textField.select();
    //                                 document.execCommand('copy');
    //                                 textField.remove();
    //                                 this.toggleToast('Successfully Copied');
    //                             } else {
    //                                 this.toggleToast('Key Not Exists',true);
    //                             }
    //                         }}>
    //                             Public Key
    //                         </Button>}
    //                             readOnly={false}/>:
    //                         <Stack distribution="trailing">
    //                             <ButtonGroup fullWidth connectedTop segmented>
    //                                 <Button size="slim"
    //                                         icon="circlePlusOutline" onClick={() => {
    //                                     if ( token[index] && token[index] !== '' ) {
    //                                         var textField = document.createElement('textarea');
    //                                         textField.innerText = token[index];
    //                                         document.body.appendChild(textField);
    //                                         textField.select();
    //                                         document.execCommand('copy');
    //                                         textField.remove();
    //                                         this.toggleToast('Successfully Copied');
    //                                     } else {
    //                                         this.toggleToast('Failed To Copy',true);
    //                                     }
    //                                 }}>
    //                                     Copy Link
    //                                 </Button>
    //                                 <Button size="slim" onClick={this.redirect.bind(this,'/panel/app/edit/' + key._id)}>Edit</Button>
    //                                 <Button icon="delete" onClick={this.deleteTheAPP.bind(this, key._id)} destructive size="slim"/>
    //                             </ButtonGroup>
    //                         </Stack>
    //                     }
    //             </Card>
    //
    //             </Collapsible>
    //             </div>
    //         ];
    //         rows.push(temp);
    //     });
    //     return rows;
    // };

    handleSave = () => {
        let { auth_url } = this.state;
        let error = false;
        auth_url.forEach(e => {
            if (e.auth_url === '') {
                error = true;
            }
        });
        if (!error) {
            requests.postRequest('sub-user/updateApp', {
                apps: auth_url
            }).then(e => {
                if (e.success) {
                    this.toggleToast(e.message);
                } else {
                    this.toggleToast(e.message, true);
                }
                this.getAllTheApps();
            })
        } else {
            this.toggleToast('Auth_url Cannot be Empty', true);
        }
    };

    handleDiscard = () => {
        let { oldState } = this.state;
        this.setState(oldState);
    };


    handleToggle = () => {
        let { activeState } = this.state;
        this.setState(activeState);
    }

    render() {
        const { showToast, modalTokenCopy, tokenToCopy, ContextualSaveBarShow, loading } = this.state;
        const toastMarkup = showToast ? (
            <Toast content={this.state.message} error={this.state.showToastError} onDismiss={this.toggleToast} />
        ) : null;
        let showContext = ContextualSaveBarShow ? <ContextualSaveBar
            message="Unsaved changes"
            saveAction={{
                onAction: this.handleSave,
            }}
            discardAction={{
                onAction: this.handleDiscard,
            }} /> : null;
        if (loading) {
            return (
                <Page
                    title="Apps"
                    fullWidth
                    primaryAction={globalState.getLocalStorage('sub_user_authenticated') ? null :
                        { content: "Create New App", onClick: () => { this.redirect('/panel/app/registration') } }}>
                    {toastMarkup}
                    {showContext}
                    <Card sectioned>
                        <Spinner />
                        <SkeletonBodyText />
                        <SkeletonBodyText />
                        <SkeletonBodyText />
                    </Card>
                </Page>
            );
        }
        return (
            <Page
                title="Apps"
                fullWidth
                primaryAction={globalState.getLocalStorage('sub_user_authenticated') ? null :
                    { content: "Create New App", onClick: () => { this.redirect('/panel/app/registration') } }}
            >
                <div className="mt-5">
                    {toastMarkup}
                    {showContext}
                    {/*<DataTable*/}
                    {/*    columnContentTypes={['text', 'numeric', 'text', globalState.getLocalStorage('sub_user_authenticated')? 'text' : 'numeric']}*/}
                    {/*    headings={[<Label>Name</Label>, <Label>status</Label>, <Label>Other Details</Label>, <Label>{globalState.getLocalStorage('sub_user_authenticated')?"Auth URl":"Action"}</Label>]}*/}
                    {/*    rows={this.renderDataTable(serverData)}/>*/}
                    <Layout>
                        {this.renderApp()}
                    </Layout>
                    <Modal
                        open={modalTokenCopy} title={"Copy Your Public Key"}
                        onClose={() => {
                            this.setState({ modalTokenCopy: false });
                        }}>
                        <Card sectioned>

                            <div dangerouslySetInnerHTML={{ __html: tokenToCopy }} />

                        </Card>
                    </Modal>
                </div>
            </Page>
        );
    }

    renderApp = () => {
        let auth_url = {};
        let token = [];
        let { serverData } = this.state;
        return Object.values(serverData).map((app, index) => {

            if (app['subUsers'] && app['subUsers']['apps']) {
                auth_url[index] = app['subUsers']['apps'];
            } else auth_url = {};
            try {
                token[index] = btoa(app['subUsers']['apps'].public_key);
                // token[index] = app['subUsers']['apps'].public_key.split(`\n`).join('<br/>')  + '<br/>';
                // token[index] = <p> {app['subUsers']['apps'].public_key.replaceAll('\n','<br/>')} </p>;
            } catch (e) {
                token[index] = '';
            }

            // let app_id = app['subUsers'] && app['subUsers']['apps'] ? app['subUsers']['apps']['app_id'] : app['_id'];
            let sub_app_id = app['subUsers'] && app['subUsers']['apps'] ? app['subUsers']['apps']['_id'] : 'N/A';

            let status = <Badge status={this.checkStatus(app['status'])} children={app.status ? app.status : 'Not Available'} />;

            return <Layout.Section oneHalf>
                <Card key={index} sectioned>
                    <Card.Header title={<><DisplayText size="large">{app.name}</DisplayText></>}>
                        {globalState.getLocalStorage('sub_user_authenticated') && Object.keys(auth_url).length > 0 ?
                            <React.Fragment>
                                {this.renderPublicKeyButton(token[index])}
                                {this.renderTokenButton(app)}
                            </React.Fragment>
                            :
                            <Stack distribution="trailing">
                                <ButtonGroup fullWidth connectedTop segmented>
                                    <Button icon={EditMajor} onClick={this.redirect.bind(this, '/panel/app/edit/' + app._id)}>Edit</Button>
                                    <Button icon="delete" onClick={this.deleteTheAPP.bind(this, app._id)} destructive />
                                </ButtonGroup>
                            </Stack>}
                    </Card.Header>
                    {status}
                    <hr />

                    <Stack distribution="fillEvenly">
                        {/*<DisplayText size="small">App Id : {app_id}</DisplayText>*/}
                        <DisplayText size="small">App Id : {sub_app_id}</DisplayText>
                    </Stack>
                    <hr />
                    <Stack distribution="fillEvenly">
                        <DisplayText size="small">About : <TextStyle variation="subdued">{app['additional_input']}</TextStyle></DisplayText>
                    </Stack>
                    <hr />


                    {globalState.getLocalStorage('sub_user_authenticated') && Object.keys(auth_url).length > 0 ?
                        <TextField label={"Redirect Url"} value={this.getValue(auth_url[index])}
                            onChange={(ch) => { this.handleAuthURLChange(ch, auth_url[index]); }} readOnly={false} />
                        : null}

                </Card>
            </Layout.Section>
        });
    };

    renderPublicKeyButton = (token) => {
        return <Button onClick={() => {
            if (token && token !== '') {
                var textField = document.createElement('textarea');
                textField.innerText = token;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                textField.remove();
                this.toggleToast('Successfully Copied');
            } else {
                this.toggleToast('Failed To Copy', true);
            }
        }} icon={ClipboardMinor}>
            Copy Secret key
        </Button>
    };

    renderTokenButton = (app) => {
        let { buttonLoading } = this.state;
        if (app.tokenData && app.tokenData.token) {
            return <Button onClick={() => {
                if (app['tokenData']['token'] && app['tokenData']['token'] !== '') {
                    var textField = document.createElement('textarea');
                    textField.innerText = app['tokenData']['token'];
                    document.body.appendChild(textField);
                    textField.select();
                    document.execCommand('copy');
                    textField.remove();
                    this.toggleToast('Successfully Copied');
                } else {
                    this.toggleToast('Failed To Copy', true);
                }
            }} icon={ClipboardMinor}>
                Copy Access Token
            </Button>
        } else {
            let apps = app['subUsers'] ? app['subUsers']['apps'] : [];
            return <Button
                onClick={this.createToken.bind(this, apps)}
                primary
                loading={buttonLoading}
                icon={AddMajorMonotone}>
                Create Access Token
            </Button>
        }
    };

    fixTheState = (state) => {
        this.setState({ oldState: JSON.parse(JSON.stringify(state)) });
    };

    redirect(url) {
        this.props.history.push(url);
    }
}

export default AppTable;