import React, { Component } from 'react'
import { Page, Card, Button, Select, Pagination, Stack, Collapsible, } from '@shopify/polaris';
import DataTableProduct from './DataTableS'
import Checkbox1 from './Checkbox1'

export default class CommonReturn extends Component {

    render() {
        // console.log(this.sat)
        // console.log(this.props)
        const options = [
            { label: '5', value: '5' },
            { label: '10', value: '10' },
            { label: '15', value: '15' },
            { label: '20', value: '20' },
            { label: '25', value: '25' },
        ];
        var arr = [];
        var keys = Object.keys(this.props.tableColumns);
        arr = keys.map((key) => {
            let value = this.props.tableColumns[key].visible;
            let name = this.props.tableColumns[key].name;
            return [<Checkbox1 name1={key} name={name} initial={value} change={this.props.changeCheck}></Checkbox1>]
        })
        return (
            <div>
                <Page title="Manage Group" fullWidth>
                        <div>
                            {/* <h3>Showing {this.props.selected * this.props.next} of {this.props.count}</h3> */}
                        </div>
                        <Pagination
                            hasPrevious={this.props.hasPrevious}
                            onPrevious={this.props.onPrev}
                            hasNext={this.props.hasNext}
                            onNext={this.props.onNext}
                        />
                        <div className="select1">
                            <Select
                                options={options}
                                onChange={this.props.handleSelectChange}
                                value={this.props.selected}
                            /></div>
                        <div className="Toggle">
                            <Button primary
                                onClick={this.props.handleToggle}
                                ariaExpanded={this.props.active}
                                ariaControls="basic-collapsible"
                            >
                                View Column
                            </Button>
                            <Collapsible
                                open={this.props.active}
                                id="basic-collapsible"
                                transition={{ duration: '150ms', timingFunction: 'ease' }}
                            >
                                <Stack>
                                    {arr}
                                </Stack>
                            </Collapsible>
                        </div>
                        <div style={{ textAlign: "right" }}><Button primary onClick={() => {
                             this.props.history.push(`/panel/acl/AddGroup`,{id: 1, title : "", description: "", code: "", from : "create", edit: true});
                            }}>Add Group</Button>{this.props.showing}</div>
                    <DataTableProduct access={this.props.data} data={this.props.reciveFilterData} columns={this.props.columns} tableColumns={this.props.tableColumns} obj={this.props.obj} handleGroupClick={this.props.handleGroupClick} />
                </Page>
            </div>
        )
    }
}
