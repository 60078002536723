import React, {Component} from 'react';
import './css/registration.css';
import {requests} from "../../../services/request";
import {Toast, RadioButton, Banner,Card, Stack, Label} from "@shopify/polaris";
import {environment} from "../../../environment/environment";

class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username:'',
            name:'',
            email:'',
            phone:'',
            password:'',
            appID : props.match.params.appid ? props.match.params.appid : null,
            marketplace : props.match.params.marketplace ? props.match.params.marketplace : null,
            region : {
                EU : ['U.A.E','Germany','Egypt','Spain','France','UK','Saudi Arabia','Italy','Turkey','Netherlands'],
                NA : ['Brazil','Canada','Mexico','US'],
                FE : ['Singapore','Australia','Japan'],
                IN : ['India']
            },
            choosenRegion : null
        };
    }

    componentDidMount() {
        if ( this.props.match.params.appid ) {
            this.setState({
                appID : this.props.match.params.appid ? this.props.match.params.appid : null,
                marketplace : this.props.match.params.marketplace ? this.props.match.params.marketplace : null,
            });
        }
    }

    onSubmit = () => {

        let { username, name, email, phone, password, domain, appID, marketplace, choosenRegion } = this.state;

        let sendData = {
            username:username,
            name:name,
            email:email,
            phone:phone,
            password:password,
            domain:domain,
            confirmation_link: environment.confirmation_link
        };

        if ( appID !== null ) sendData['app_id'] = appID;
        if ( marketplace !== null ) sendData['marketplace'] = marketplace;
        if ( choosenRegion !== null ) sendData['region'] = choosenRegion;
        requests.postRequest('user/create', sendData).then(e => {
            this.toggleToast(e.message, !e.success);
        });

    };

    toggleToast = (message, error = false) => {
        this.setState({
            showToast:!this.state.showToast,
            showToastError: error,
            message: message,
        });
    };

    render() {
        const {showToast, appID, marketplace,region,choosenRegion } = this.state;
        const toastMarkup = showToast ? (
            <Toast content={this.state.message} error={this.state.showToastError} onDismiss={this.toggleToast} />
        ) : null;
        return (
            <React.Fragment>
                {toastMarkup}
                <div className="wrapper-auth fadeInDown-auth">
                    <div id="formContent-auth">
                        {/*Tabs Titles*/}
                        {/*Icon*/}
                        <div className="fadeIn-auth first">
                            <img src="https://k4h6h8w8.stackpathcdn.com/skin/frontend/cedcomnew/default/images/logo.png" id="icon" alt="User Icon"/>
                        </div>
                        {/*Login Form */}
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            this.onSubmit();
                        }}>
                            {marketplace === 'amazon' &&<div style={{padding:"5%"}}>
                                    <Card sectioned>
                                        <Stack distribution="equalSpacing">
                                            {Object.keys(region).map(key => {
                                                return <RadioButton
                                                    checked={choosenRegion === key}
                                                    label={key} onChange={() => {
                                                    this.setState({choosenRegion : key});
                                                }
                                                }
                                                />
                                            })}
                                        </Stack>
                                    </Card>
                                </div>}

                            {
                                region[choosenRegion] ? <Banner title="Country Supported" status="info">
                                    <Stack>
                                        {region[choosenRegion].map(e => {
                                            return <Label>•{e}</Label>;
                                        })}
                                    </Stack>
                                </Banner> : null
                            }

                            <input
                                type="text"
                                id="login"
                                className="fadeIn-auth second"
                                value={this.state.username}
                                onChange={(e) => {this.setState({username:e.target.value})}}
                                name="Username"
                                placeholder="Username"/>
                            <input
                                type="text"
                                id="name"
                                className="fadeIn-auth second"
                                value={this.state.name}
                                onChange={(e) => {this.setState({name:e.target.value})}}
                                name="Name"
                                placeholder="Full Name"/>
                            <input
                                type="text"
                                id="phone"
                                className="fadeIn-auth second"
                                value={this.state.phone}
                                onChange={(e) => {this.setState({phone:e.target.value})}}
                                name="Phone"
                                placeholder="Phone Number"/>
                            <input
                                type="text"
                                id="email"
                                className="fadeIn-auth second"
                                value={this.state.email}
                                onChange={(e) => {this.setState({email:e.target.value})}}
                                name="Email"
                                placeholder="Email eg. abc@xyz.com"/>
                            <input
                                type="text"
                                id="domain"
                                className="fadeIn-auth second"
                                value={this.state.domain}
                                onChange={(e) => {this.setState({domain:e.target.value})}}
                                name="Email"
                                placeholder="DOMAIN : e.g www.xyz.com"/>
                            <input
                                type="password"
                                id="password"
                                value={this.state.password}
                                onChange={(e) => {this.setState({password:e.target.value})}}
                                className="fadeIn-auth second"
                                name="login"
                                placeholder="password"/>
                            <input
                                type="submit"
                                className="fadeIn-auth fourth"
                                value="Register"/>
                        </form>
                        {/*Remind Passowrd*/}
                        <div id="formFooter-auth">
                            <a
                                onClick={(e) =>{
                                    this.props.history.push('/auth/login');
                                }}
                                className="underlineHover-auth"
                                href="javascript:void(0)">
                                Login
                            </a>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Registration;