/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import CheckboxTree from 'react-checkbox-tree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faCheckCircle, faCircle, faFolder, faFolderOpen, faFile, faExpand, faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { ChevronRightMinor, ChevronDownMinor } from '@shopify/polaris-icons';
import { Card, Icon, Page, TextField } from '@shopify/polaris';
import { requests } from "../../../../services/request";

export default function ACL(props) {
    const [nodesMod, setNodeMod] = useState([]);
    const [visiblity, setVisiblity] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const [Title, setTitle] = useState(props.location.state.title);

    const [Code, setCode] = useState(props.location.state.code);

    const [Description, setDescription] = useState(props.location.state.description);

    // const [DonotReRender, setDonotReRender] = useState(false);

    // const [loasd, setloasd] = useState(initialState)

    // console.log(props);

    const fetch = () => {
        let toDisplay = [], checked = [];
        let strVisible = [];
        requests.getRequest("acl-group/getRoleResource?group_code=admin")
            .then((e) => {
                if (e.success) {
                    // console.log(e);
                    toDisplay = e.data.resources
                    let module = [], lenMod = 0;
                    toDisplay.forEach(e => {
                        let flag = module.includes(e.module)
                        if (!flag) {
                            module[lenMod] = e.module
                            lenMod++;
                        }
                    }); let MajorObj = {};
                    for (let i = 0; i < module.length; i++) {
                        // eslint-disable-next-line no-loop-func
                        toDisplay.forEach(e => {
                            if (e.module === module[i]) {
                                MajorObj[module[i]] = { ...MajorObj[module[i]] }
                                MajorObj[module[i]][e.controller] = { ...MajorObj[module[i]][e.controller] };
                                MajorObj = {
                                    ...MajorObj, [module[i]]: {
                                        ...MajorObj[module[i]],
                                        [e.controller]: {
                                            ...MajorObj[module[i]][e.controller],
                                            [e.action]: {
                                                id: e.id,
                                                visible: visiblity[e.id]
                                            }
                                        }
                                    }
                                }
                            }
                        })
                    }
                    // console.log(MajorObj);
                    let nodes = []; let Expand = []; let ExpIndex = 0;
                    Object.keys(MajorObj).forEach((eleModule, indexMod) => {
                        let ObjContToAdd = [];
                        Object.keys(MajorObj[eleModule]).forEach((eleCont, indexCont) => {
                            let ObjActToAdd = [];
                            Object.keys(MajorObj[eleModule][eleCont]).forEach((eleAct, indexAct) => {
                                ObjActToAdd[indexAct] = {
                                    label: eleAct, value: MajorObj[eleModule][eleCont][eleAct].id
                                }
                                strVisible.push(MajorObj[eleModule][eleCont][eleAct].id);
                            })
                            ObjContToAdd[indexCont] = {
                                label: eleCont, value: `${eleModule}-${eleCont}`, children: ObjActToAdd
                            }
                            Expand[ExpIndex] = `${eleModule}-${eleCont}`
                            ExpIndex++;
                        })
                        nodes[indexMod] = { label: eleModule, value: `${eleModule}`, children: ObjContToAdd }
                        Expand[ExpIndex] = eleModule
                        ExpIndex++;
                    })
                    // console.log(nodes);
                    setNodeMod(nodes);
                    setExpanded(Expand);
                }
            });
        if (props.location.state.id == "1")
            setVisiblity(strVisible)
        else {
            requests.getRequest(`acl-group/get?id=${props.location.state.id}`)
                .then((e) => {
                    if (e.success) {
                        checked = e.data.resources
                        let visiblity1 = [];
                        for (let i = 0; i < checked.length; i++) {
                            visiblity1[i] = checked[i].resource_id
                        }
                        setVisiblity(visiblity1);
                    }
                });
        }
    }
    useEffect(() => {
        fetch();
        // console.log(props, "propsShow");
        setTitle(props.location.state.title);
        setCode(props.location.state.code);
        setDescription(props.location.state.description);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSave = (checked) => {
        var obj = {
            group_code: Code, title: Title, description: Description
        }
        for (let i = 0; i < checked.length; i++) {
            let resources = `resources[${i}]`;
            obj = {
                ...obj,
                [resources]: checked[i]
            }
        }
        requests.getRequest(`acl-group/${props.location.state.from}`, obj).then((e) => { });

        props.history.push("/panel/acl");
    }

    return (
        <Page fullWidth title={Title} fullWidth primaryAction={{ content: "Back", onAction: () => { props.history.push("/panel/acl") } }}>
            <Card sectioned>
                <div style={{ width: "90%" }}>
                    <TextField label="Group Title" value={Title} onChange={(e) => { setTitle(e); }} disabled={!props.location.state.edit}></TextField>
                    <TextField label="Group Code" value={Code} onChange={(e) => { setCode(e); }} disabled={!props.location.state.edit}></TextField>
                </div>
                <h4>Group Description</h4> <TextField style={{ height: "100px", width: "80%", fontSize: "14px" }} onChange={(e) => { setDescription(e) }} value={Description} disabled={!props.location.state.edit}></TextField>
            </Card>

            <TreeView {...props} nodes={nodesMod} checked={visiblity} expanded={expanded} handleSave={handleSave} />

        </Page>
    )

}

const TreeView = (props) => {



    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [nodes, setNodes] = useState([]);

    useEffect(() => {
        setChecked(props.checked)
        setExpanded(props.expanded);
        setNodes(props.nodes)
    }, [props.nodes, props.checked, props.expanded])



    return (
        <Card
            sectioned
            secondaryFooterAction={{ content: "Back", onAction: () => { props.history.push("/panel/acl") } }}
            primaryFooterAction={{ content: "save", onAction: () => props.handleSave(checked), disabled: !props.location.state.edit }}>
            <div style={{ height: "400px", overflow: "auto" }}>
                <CheckboxTree
                    checked={checked}
                    showExpandAll={true}
                    expanded={expanded}
                    nodes={nodes}
                    onCheck={(checked) => setChecked(checked)}
                    onExpand={(expanded) => setExpanded(expanded)}
                    icons={{
                        check: <FontAwesomeIcon icon={faCheckCircle} color={'#000'} />,
                        uncheck: <FontAwesomeIcon icon={faCircle} color={"rgb(200,200,200)"} />,
                        halfCheck: <FontAwesomeIcon icon={faMinusCircle} color={"rgb(80,80,80)"} />,
                        expandClose: <Icon source={ChevronRightMinor} backdrop={false} />,
                        // expandClose:<FontAwesomeIcon icon={faArrowLeft} color={"rgb(80,80,80)"}/>,
                        expandOpen: <Icon source={ChevronDownMinor} backdrop={false} />,
                        expandAll: <FontAwesomeIcon icon={faExpand} />,
                        collapseAll: <FontAwesomeIcon icon={faCompressArrowsAlt} />,
                        parentClose: <FontAwesomeIcon icon={faFolder} color={"#000"} />,
                        parentOpen: <FontAwesomeIcon icon={faFolderOpen} color={"#000"} />,
                        leaf: <FontAwesomeIcon icon={faFile} color={"#000"} />
                    }}
                />
            </div>
        </Card>
    );
}