import React, {Component} from 'react';

import {
    Badge,
    Card,
    DataTable,
    Page,
    Stack,
    Button,
    TextField,
    TextStyle,
    ButtonGroup,
    Toast,Spinner,SkeletonBodyText,
    Label,
    ContextualSaveBar
} from "@shopify/polaris";

class About extends Component {
    render() {
        return (
            <Card sectioned>

            </Card>
        );
    }

    redirect(url) {
        this.props.history.push(url);
    }

}

export default About;