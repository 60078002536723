import CommonReturn from './CommonReturn'
import './style.css'
import React, { Component } from 'react'
import { requests } from "../../../../services/request";


class Product extends Component {

    constructor(props) {
        console.log(props)
        super(props);
        this.state = {
            data: undefined,
            selected: 5,
            next: 1,
            count: 0,
            hasPrevious: false,
            hasNext: false,
            active: false,
            obj: {},
            tableColumns: {
                id: {
                    name: "ID",
                    type: 'number',
                    visible: true,
                    query: 'user_id',
                },
                code: {
                    name: "Code",
                    type: 'text',
                    visible: true
                },
                description: {
                    name: "Description",
                    type: 'text',
                    visible: true
                },
                View_Group: {
                    name: "View Group",
                    type: 'button',
                    visible: true
                },
                Edit_Group: {
                    name: "Edit Group",
                    type: 'button',
                    visible: true
                }
            }
        }
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }
    reciveFilterData = (obj) => {
        console.log(obj)
        let keys = Object.keys(obj);
        let values = Object.values(obj);
        let filterOnColumn = keys.map((arr) => { return arr.substring(0, arr.length - 1) })
        let whatFilter = keys.map((arr) => arr[arr.length - 1])
        let filterOnColumnCor = [];
        for (let i = 0; i < filterOnColumn.length; i++) {
            filterOnColumnCor[i] = `filter[${this.state.tableColumns[filterOnColumn[i]].query}][${whatFilter[i]}]`
        }
        let obj2 = {
            activePage: this.state.next,
            count: this.state.selected,
        }
        for (let i = 0; i < filterOnColumnCor.length; i++) {
            obj2[filterOnColumnCor[i]] = values[i];
        }
        if (this.state.selected * this.state.next > this.count) { }
        requests.getRequest('acl-group/getAll', obj2)
            .then(e => {
                this.setState((preState) => {
                    preState.data = e.data.rows;
                    preState.count = e.data.count;
                    if (this.state.selected * this.state.next >= preState.count) {
                        preState.hasNext = false;
                    } else {
                        preState.hasNext = true;
                    }
                    return preState;
                })
            });
    }

    handleGroupClick = (obj) => {
        console.log(obj, "Objcheck");
        if (obj.toFrom === "Edit Group")
            this.props.history.push(`/panel/acl/viewGroup#${obj.id}`, { ...obj, from: "update", edit: true });
        else {
            this.props.history.push(`/panel/acl/viewGroup#${obj.id}`, { ...obj, from: "update", edit: false });
        }
    }

    componentDidMount() {
        this.reciveFilterData(this.state.obj);
    }
    handleSelectChange(e) {
        this.setState({
            next: 1,
            selected: e
        }, () => this.reciveFilterData(this.state.obj))
    }
    onPrev = () => {
        if (this.state.next > 1) {
            if (this.state.next === 2) {
                this.setState({
                    hasPrevious: false
                })
            }
            this.setState({
                next: this.state.next - 1,
            }, () => this.reciveFilterData(this.state.obj))
        }
    }
    onNext = () => {
        this.setState({
            next: this.state.next + 1,
            hasPrevious: true
        }, () => this.reciveFilterData(this.state.obj))

    }



    handleToggle = () => {
        this.setState({
            active: !this.state.active
        })
    }
    changeCheck = (val) => {
        this.setState((prevState) => {
            prevState.tableColumns[val].visible = !prevState.tableColumns[val].visible;
            return prevState

        })
    }

    render() {
        if (this.state.data !== undefined) {
            let show = !(this.state.selected * this.state.next >= this.state.count) ? this.state.selected * this.state.next : this.state.count;
            return (
                <CommonReturn {...this.state}
                    {...this.props}
                    changeCheck={this.changeCheck}
                    onPrev={this.onPrev}
                    onNext={this.onNext}
                    handleSelectChange={this.handleSelectChange}
                    handleToggle={this.handleToggle}
                    reciveFilterData={this.reciveFilterData}
                    handleGroupClick={this.handleGroupClick}
                    showing={<h3> Showing {show} of {this.state.count}</h3>}
                />
            )
        } else {
            return null
        }
    }
}

export default Product